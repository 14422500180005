/**
 * 2020.05.28 by lyt 优化
 * 修改一下配置时，需要每次都清理 `window.localStorage` 浏览器永久缓存，配置才会生效
 */
const themeConfigModule = {
	namespaced: true,
	state: {
		themeConfig: {
			// 是否开启布局配置抽屉
			isDrawer: false,

			/**
			 * 全局主题
			 */
			// 默认 primary 主题颜色
			primary: '#409eff',

			/**
			 * 菜单 / 顶栏
			 * 请注意：
			 * 需要同时修改 `/@/theme/common/var.scss` 对应的值，
			 * 不提供像 vue-next-admin 一样的实现
			 */
			// 默认顶栏导航背景颜色
			topBar: '#ffffff',
			// 默认顶栏导航字体颜色
			topBarColor: '#606266',
			// 默认菜单导航背景颜色
			menuBar: '#545c64',
			// 默认菜单导航字体颜色
			menuBarColor: '#eaeaea',
			// 默认分栏菜单背景颜色
			columnsMenuBar: '#545c64',
			// 默认分栏菜单字体颜色
			columnsMenuBarColor: '#e6e6e6',

			/**
			 * 界面设置
			 */
			// 是否开启菜单水平折叠效果
			isCollapse: true,
			// 是否开启菜单手风琴效果
			isUniqueOpened: true,

			/**
			 * 界面显示
			 */
			// 是否开启侧边栏 Logo
			isShowLogo: true,
			// 是否开启 Footer 底部版权信息
			isFooter: false,
			// 是否开启灰色模式
			isGrayscale: false,
			// 是否开启色弱模式
			isInvert: false,

			/**
			 * 全局网站标题 / 副标题
			 */
			// 网站主标题（菜单导航、浏览器当前网页标题）
			globalTitle: 'Hippo Limited',
			// 网站副标题（登录页顶部文字）
			globalViceTitle:'Hippo',// 'Hippo',
			// 网站描述（登录页顶部文字）
			globalViceDes: '',
			// 默认初始语言，可选值"<zh|en|hk>"，默认 zh
			globalI18n: 'en',
			// 默认全局组件大小，可选值"<|medium|small|mini>"，默认 ''
			globalComponentSize: 'mini',
		},
	},
	mutations: {
		// 设置布局配置
		getThemeConfig(state, data) {
			state.themeConfig = data;
		},
	},
	actions: {
		// 设置布局配置
		setThemeConfig({ commit }, data) {
			commit('getThemeConfig', data);
		},
	},
};

export default themeConfigModule;
