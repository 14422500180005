import Vue from 'vue';

import { Local } from '@/utils/storage';

import VueRouter from 'vue-router'
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import('@/layout/index.vue'),
    redirect: '/login',
  },
  {
    path: '/login',
    component: () => import('@/views/login/index.vue'),
  },
  {
    path: '/home',
    component: () => import('@/layout/index.vue'),
    redirect: '/home/index',
    children: [
      {
        path: '/home/index',
        component: () => import('@/views/home/home.vue'),
      },
    ]
  },
]

// 加载静态路由
const createRouter = () =>
	new VueRouter({
		routes: routes,
	});

// 创建路由
const router = createRouter();

router.beforeEach((to, from, next) => {
  let token = Local.get('token');
  console.log(!token, to.path === '/login');
  if (to.path === '/login' && !token) {
    next();
  }else{
    if (!token) {
      next('/login');
    }else {
      next();
    }
  }
});

// 导出路由
export default router;